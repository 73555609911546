import React from "react"
import { graphql } from "gatsby"

import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/developers/hero"
import Clients from "components/sections/developers/clients"
import Layers from "components/sections/layers"
import Steps from "components/sections/developers/steps"
import Code from "components/sections/developers/code"
import Banner from "components/sections/common/banner"
import Pools from "components/sections/developers/pools"

const Developers = ({ data }) => {
  const { frontmatter } = data.developers.edges[0].node

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: frontmatter.useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout headerContent={data.header.edges} footerContent={data.footer.edges} lang="fr">
        <SEO
          frontmatter={frontmatter}
          lang="fr"
        />
        <Hero content={data.hero.edges} />
        <Clients  content={data.clients.edges} />
        <Layers content={data.layers.edges} />
        <Steps content={data.steps.edges} />
        <Code content={data.code.edges} />
        <Banner content={data.banner.edges} />
        <Pools content={data.pools.edges}/>
        <div className="gradient">

        </div>
      </Layout>
    </GlobalStateProvider>
  )
}


export default Developers

export const pageQuery = graphql`
  {
    developers: allMdx(filter: { fileAbsolutePath: { regex: "/developers/fr/developers/" } }) {
      edges {
        node {
          frontmatter {
            title
            url
            description
            keywords
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/developers/fr/hero/" } }) {
      edges {
        node {
          body
          frontmatter {
            subtitlePrefix
            buttonWhiteText
            buttonEmptyText
            buttonWhiteLink
            buttonEmptyLink
          }
        }
      }
    }
    clients: allMdx(filter: { fileAbsolutePath: { regex: "/developers/fr/clients/" } }) {
      edges {
        node {
          exports {
            shownItems
            clients {
              alt
              icon
              width
            }
          }
          frontmatter {
            title
          }
        }
      }
    }
    layers: allMdx(filter: { fileAbsolutePath: { regex: "/index/fr/layers/" } }) {
      edges {
        node {
          exports {
            shownItems
            items {
              title
              subtitle
              text
              link
              logo {
                childImageSharp {
                  gatsbyImageData(width: 50, formats: [WEBP, AVIF], quality: 90)
                }
              }
            }
          }
          frontmatter {
            label
            title1
            title2
            textLeft
            textRight
          }
        }
      }
    }
    steps: allMdx(filter: { fileAbsolutePath: { regex: "/developers/fr/steps/" } }) {
      edges {
        node {
          frontmatter {
            title
            step1
            step2
            step3
            image1 {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            image2 {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            image3 {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
      }
    }
    code: allMdx(filter: { fileAbsolutePath: { regex: "/developers/fr/code/" } }) {
      edges {
        node {
          body
          frontmatter {
            title
          }
        }
      }
    }
    stack: allMdx(filter: { fileAbsolutePath: { regex: "/developers/fr/stack/" } }) {
      edges {
        node {
          body
          frontmatter {
            title
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
      }
    }
    banner: allMdx(filter: { fileAbsolutePath: { regex: "/developers/fr/banner/" } }) {
      edges {
        node {
          frontmatter {
            title
            buttonTitle
            link
            external
          }
        }
      }
    }
    pools: allMdx(filter: { fileAbsolutePath: { regex: "/developers/fr/pools/" } }) {
      edges {
        node {
          exports {
            pools {
              title
              subtitle
              image {
                childImageSharp {
                  gatsbyImageData(quality: 90)
                }
              }
            }
          }
          frontmatter {
            title
          }
          body
        }
      }
    }
    footer: allMdx(filter: { fileAbsolutePath: { regex: "/layout/fr/footer/" } }) {
      edges {
        node {
          body
          frontmatter {
            label
            title
            titlePrefix
            subtitle
            subtitlePrefix
            buttonBookingText
            buttonBookingLink
            buttonSignupText
            buttonSignupLink
            copyRight
          }
          exports {
            items {
              name
              links {
                text
                link
                external
              }
            }
          }
        }
      }
    }
    header: allMdx(filter: { fileAbsolutePath: { regex: "/layout/fr/header/" } }) {
      edges {
        node {
          frontmatter {
            demoButtonText
            demoButtonLink
            registerButtonText
            registerButtonLink
            bookingButtonText
            bookingButtonLink
          }
          exports {
            items {
              name
              modal {
                left {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                }
                right {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                  media {
                    name
                    photo
                    alt
                    text
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
